import Image from 'src/components/image';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { timestamp, useDateUpdate } from 'src/utils/helper';
import { useCountviews } from 'src/utils/countview';
// import { Viewer } from '.';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import _ from 'lodash';

const CardFullHighlight = ({
  data,
  path,
  showBlurb = false,
  pinTag = true,
  byline = true,
  showCountview = true,
  showContent = true,
  eventDataLayer
}) => {
  if (_.isEmpty(data)) return null;
  const [countView, setCountView] = useState(data.pageviews || 0);
  useEffect(
    () =>
      (async () => {
        try {
          const _pageviews = await useCountviews(data);
          setCountView(_pageviews);
        } catch (err) {
          console.error(`${timestamp()} ==========> CARD_FULL_HL ERROR :`, err.message);
        }
      })(),
    []
  );
  const badge = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;

  return (
    <CardWrapper>
      <a
        href={data?.link}
        tabIndex='-1'
        title={data?.title}
        target='_self'
        className='card-wrapper'
        //! DATALAYER
        onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
      >
        <div className='card-full-thumb'>
          <Image image={data?.image} title={data?.title} type='CARD_LARGE' />
        </div>
        {showContent && (
          <div className='card-full-content container'>
            <div className='detail'>
              {pinTag && (
                <div className='tag-pin'>
                  <p>{badge}</p>
                </div>
              )}
              <div className='card-content'>
                <div className='meta'>
                  <p className='date-time'>
                    <span> {useDateUpdate(data?.published_at)} </span>
                  </p>
                  {/* {showCountview && <Viewer data={countView} />} */}
                </div>
              </div>
              <h2 className='text-excerpt-3'>{data?.title}</h2>
              {showBlurb && data?.blurb && <p className='blurb text-excerpt-2'>{data?.blurb}</p>}
            </div>
          </div>
        )}
      </a>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  position: relative;
  .card-full-thumb {
    /* .resolution-image {      
      border-radius: 3px;
    } */
    @media (max-width: 475px) {
      background: transparent;
    }
  }
  .card-full-content {
    position: relative;
    top: -20px;
    .detail {
      position: relative;
      background-color: #ffe000;
      padding: 30px 40px;
      .tag-pin {
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 767px) {
          padding: 2px 10px;
          p {
            font-size: 10px;
          }
        }
      }
    }
    h2 {
      margin: 10px 0 5px 0;
      text-align: center;
      line-height: 1.25;
      &:hover {
        text-decoration: underline;
      }
    }
    .card-content {
      position: absolute;
      right: 0;
      top: 0;
    }
    .meta {
      margin-top: 5px;
      p,
      svg,
      span {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        @media (max-width: 767px) {
          font-size: 10px;
        }
      }

      .pageview {
        &:before {
          content: '/';
          color: #000;
          padding: 5px;
        }
      }
    }
  }
`;

export default CardFullHighlight;
