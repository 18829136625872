import dynamic from 'next/dynamic';
import LazyLoad from 'react-lazyload';
import { APP_IMG } from 'src/constants';

const ContentLoader = dynamic(import('src/components/content-loader'));

const Image = ({ title, image, ignoreLazy, horizontal = false, exLazy = '', external = false, type }) => {
  let elem = (
    <div className='resolution-image'>
      <img src={`${APP_IMG}/static/default.jpg`} alt={title || 'image'} title={title || 'image'} loading='lazy' width='100%' height='100%' />
    </div>
  );
  if (image) {
    let imgUrl = image;
    if (!external) {
      imgUrl = `${APP_IMG}${image}`;
    }
    // const imgUrl = image;
    switch (type) {
      case 'FEATURE_IMAGE':
        return (elem = (
          <>
            <picture className='resolution-image'>
              <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} media={'(max-width: 767px)'} type='image/webp' />
              <source srcSet={`${imgUrl}?x-image-process=style/MD`} media={'(max-width: 767px)'} type='image/jpeg' />
              <source srcSet={`${imgUrl}?x-image-process=style/LG-webp`} type='image/webp' />
              <img
                src={`${imgUrl}?x-image-process=style/LG-webp`}
                alt={title}
                title={title}
                loading='lazy'
                className={`${exLazy}`}
                width='100%'
                height='100%'
              />
            </picture>
          </>
        ));
      case 'COVER_IMAGE':
        return (elem = (
          <>
            <picture className='resolution-image'>
              {/* <source srcSet={`${imgUrl}?x-image-process=style/LG-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/LG`} media={'(max-width: 767px)'} type='image/jpeg' /> */}
              <source srcSet={`${imgUrl}?x-image-process=style/XL-webp`} type='image/webp' />
              <img
                src={`${imgUrl}?x-image-process=style/XL`}
                alt={title}
                title={title}
                loading='lazy'
                className={`${exLazy}`}
                width='100%'
                height='100%'
              />
            </picture>
          </>
        ));
      case 'CARD_LARGE':
        return (elem = (
          <>
            <picture className='resolution-image'>
              <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} media={'(max-width: 767px)'} type='image/webp' />
              <source srcSet={`${imgUrl}?x-image-process=style/MD`} media={'(max-width: 767px)'} type='image/jpeg' />
              <source srcSet={`${imgUrl}?x-image-process=style/lg-webp`} type='image/webp' />
              <img
                src={`${imgUrl}?x-image-process=style/lg-webp`}
                alt={title}
                title={title}
                loading='lazy'
                className={`${exLazy}`}
                width='100%'
                height='100%'
              />
            </picture>
          </>
        ));
      case 'CARD_MEDIUM':
        return (elem = (
          <>
            <picture className='resolution-image'>
              {/* <source srcSet={`${imgUrl}?x-image-process=style/XS-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/XS`} media={'(max-width: 767px)'} type='image/jpeg' /> */}
              <source srcSet={`${imgUrl}?x-image-process=style/md-webp`} type='image/webp' />
              <img
                src={`${imgUrl}?x-image-process=style/md`}
                alt={title}
                title={title}
                loading='lazy'
                className={`${exLazy}`}
                width='100%'
                height='100%'
              />
            </picture>
          </>
        ));
      case 'CARD_SMALL':
        return (elem = (
          <>
            <picture className='resolution-image'>
              {/* <source srcSet={`${imgUrl}?x-image-process=style/SS-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/SS`} media={'(max-width: 767px)'} type='image/jpeg' /> */}
              <source srcSet={`${imgUrl}?x-image-process=style/xs-webp`} type='image/webp' />
              <img
                src={`${imgUrl}?x-image-process=style/xs`}
                alt={title}
                title={title}
                loading='lazy'
                className={`${exLazy}`}
                width='100%'
                height='100%'
              />
            </picture>
          </>
        ));
      case 'NONE_OPTION':
        return (elem = (
          <>
            <picture className='resolution-image'>
              <source srcSet={`${imgUrl}`} type='image/webp' />
              <img src={`${imgUrl}`} alt={title} title={title} loading='lazy' className={`${exLazy}`} width='100%' height='100%' />
            </picture>
          </>
        ));
      default:
        return (elem = (
          <>
            <picture className='resolution-image'>
              <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} media={'(max-width: 768px)'} type='image/webp' />
              <source srcSet={`${imgUrl}?x-image-process=style/MD`} media={'(max-width: 768px)'} type='image/jpeg' />
              <source srcSet={`${imgUrl}?x-image-process=style/LG-webp`} media={'(max-width: 1440px)'} type='image/webp' />
              <img
                src={`${imgUrl}?x-image-process=style/LG-webp`}
                alt={title}
                title={title}
                loading='lazy'
                className={`${exLazy}`}
                width='100%'
                height='100%'
              />
            </picture>
          </>
        ));
    }
  }

  if (ignoreLazy) return elem;
  return <LazyLoad placeholder={<ContentLoader hasText={false} horizontal={horizontal} />}>{elem}</LazyLoad>;
};

export default Image;
